interface Versions {
  forms_version: string;
  templater_version: string;
}

let versionsResult: Promise<Versions | null> | undefined;

async function _getVersions(appVersion: string): Promise<Versions | null> {
  const result = await fetch(`https://gql.assist.si/api/rest/GetAngularVersions/${appVersion}`);

  if (!result.ok) {
    throw new Error(`Failed to get app library versions: ${result.status} ${result.statusText}`);
  }

  const data = await result.json();
  return data?.data;
}

async function getVersions(appVersion: string): Promise<Versions | null> {
  if (versionsResult) return versionsResult;

  try {
    const vr = _getVersions(appVersion);
    versionsResult = vr;
    return await vr;
  } catch (error) {
    versionsResult = undefined;
    throw error;
  }
}

async function safeGetVersions(tag: string) {
  try {
    const versions = await getVersions(tag?.length && !tag.startsWith('$$') ? tag : 'next');
    if (!versions) return getVersions('next');

    return versions;
  } catch {
    return getVersions('next');
  }
}

async function safeSelectVersion(tag: string, type: keyof Versions): Promise<string> {
  const version = (await safeGetVersions(tag))?.[type];

  if (!version) throw new Error('Invalid app library versions returned from server!');
  return version;
}

export async function getFormsVersion(tag: string): Promise<string> {
  return safeSelectVersion(tag, 'forms_version');
}

export async function getTemplaterVersion(tag: string): Promise<string> {
  return safeSelectVersion(tag, 'templater_version');
}
