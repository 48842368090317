export class TenantId {
  static Ncr = 3;
  static NcrAlbania = 230;
  static Rcg = 2;
  static Bent = 122;

  static Halcom = 123;
  static HalcomCC = 304;
  static HalcomBA = 439;
  static HalcomRS = 472;

  static SavaMkt = 121;

  static LancomTehnika = 124;
  static LancomInfrastructure = 267;
  static LancomDms = 266;
  static LancomPoslovnaInformatika = 265;

  static Projektip = 126;
  static GeneraliInv = 159;

  static InterexportLocalIt = 194;
  static InterexportMedicina = 232;
  static InterexportKm = 270;
  static InterexportKmHrv = 479;
  static InterexportKmSr = 480;
  static InterexportRadiologija = 481;

  static Btc = 195;
  static Kontron = 373;
  static ZZI = 372;
  static Medilab = 406;
}
